import { $axios } from '@/utils/axios';

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    getFormTemplate: ({dispatch}) => {
      dispatch('setLoading', true, {root: true})

      return new Promise((resolve, reject) => {
        $axios.get(`/form_template`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => dispatch('setLoading', false, {root: true}))
      })        
    },
    getForm: ({dispatch}, id) => {
      dispatch('setLoading', true, {root: true})
      const params = [
        `form_template_id=${id }`
      ].join('&')       

      return new Promise((resolve, reject) => {
        $axios.get(`/form_input?${params}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => dispatch('setLoading', false, {root: true}))
      })        
    },
    postForm: ({dispatch}, payload) => {
      dispatch('setLoading', true, {root: true})
      const method = 'post'
      const url = `/form_input`

      return new Promise((resolve, reject) => {
        $axios({
          method,
          url,
          data: payload
        })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => dispatch('setLoading', false, {root: true}))
      })        
    },
  }
}    